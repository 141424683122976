<template>
    <div style="max-width: 800px;" class="mx-auto mt-3">
        <b-progress :value="step * 90 / 4" max="100" class="my-2" />
        <b-card v-if="step == 0">
            <div v-for="(q, index) in questions" :key="index">
                <h4>{{ q.text }}</h4>
                <div class="d-flex" :class="{ 'mb-2': index !== questions.length - 1 }">
                    <vs-radio v-model="form[q.key]" :vs-name="q.key" :vs-value="false" class="mr-5">
                        <h4 class="mb-0 ml-50">لا</h4>
                    </vs-radio>

                    <vs-radio v-model="form[q.key]" :vs-name="q.key" :vs-value="true">
                        <h4 class="mb-0 ml-50">نعم</h4>
                    </vs-radio>
                </div>
            </div>
            <div v-if="form.with_trafic_violation == true">
                <p class="text-danger">
                    بما ان الحادث تم نتيجة مخالفة مرورية، يجب الإتصال على المرور لمباشرة موقع الحادث
                </p>
                <div class="text-center">
                    <b-button variant="primary" href="tel:993"><feather-icon icon="PhoneIcon" class="mr-50" />إتصال
                        993</b-button>
                </div>
            </div>


        </b-card>
        <b-card v-if="step == 1">
            <div class="text-center">
                <h3>عدد الأطراف المتضررين</h3>
                <vs-input-number class="count-input m-auto" dir="ltr" :min="1" :max="10" v-model="form.parties_count" />

                <div v-if="form.parties_count > maxPartiesForReport">
                    <p class="text-danger">لا يمكن تقديم بلاغ في حال كان عدد المتضررين أكثر من <strong>{{
                        maxPartiesForReport }}</strong>، الرجاء الإتصال على المرور</p>
                    <b-button size="sm" variant="primary" href="tel:993"><feather-icon icon="PhoneIcon"
                            class="mr-50" />إتصال 993</b-button>
                </div>
            </div>
        </b-card>
        <div v-if="step == 2">
            <h3 class="text-center mb-1">بيانات اللوحات</h3>
            <b-card class="mb-3" v-for="i in parseInt(form.parties_count)" :key="i">
                <div class="mb-2">
                    <h2 class="text-center">الطرف {{ i }}</h2>
                    <div class="d-flex align-items-center mb-50" cols="12">

                        <h3 class="mr-2">نوع اللوحة</h3>
                        <div>

                            <div class="d-flex">
                                <vs-radio v-model="settings[i - 1].type" :vs-name="i" :vs-value="0" class="mr-2">
                                    <h4 class="mb-0 ml-50">خصوصي</h4>
                                </vs-radio>

                                <vs-radio v-model="settings[i - 1].type" :vs-name="i" :vs-value="1">
                                    <h4 class="mb-0 ml-50">نقل</h4>
                                </vs-radio>
                            </div>


                        </div>

                    </div>

                    <div class="d-flex align-items-center mb-50" cols="12">
                        <h3 class="mb-0 mr-2">الحروف:</h3>

                    </div>
                    <b-row class="align-items-center justify-content-center" style="max-width: 500px; margin:auto;">
                        <b-col sm="12" md="auto" class="text-center" dir="ltr">
                            <p class="mb-50">اللغة العربية</p>
                            <div class="justify-content-center d-flex">
                                <otp-input :inputs="settings[i - 1].arabicInputs" dir="rtl" input-classes="otp-input" lang="ar"
                                    separator="-" :num-inputs="3" :should-auto-focus="true" input-type="text"
                                    @inputsChanged="inputsChanged($event, i - 1)"
                                    @on-keydown="lettersInputKeydown($event, 'ar')" />
                            </div>

                        </b-col>
                        <span class="my-0 my-1">أو</span>
                        <b-col sm="12" md="auto" class="text-center">
                            <p class="mb-50">اللغة الإنقليزية</p>
<div class="justify-content-center d-flex">
                                <otp-input :inputs="settings[i - 1].englishInputs" dir="ltr" input-classes="otp-input"
                                    separator="-" lang="en" :num-inputs="3" :should-auto-focus="true" input-type="text"
                                    @on-keydown="lettersInputKeydown($event, 'en')"
                                    @inputsChanged="inputsChanged($event, i - 1)" /></div>
                        </b-col>
                    </b-row>
                </div>
                <div class="mt-1 pt-25">
                    <div class="d-flex align-items-center mb-50" cols="12">
                        <h3 class="mb-0 mr-2">الأرقام:</h3>
                    </div>
                    <div class="justify-content-center d-flex">
                        <otp-input dir="ltr" input-classes="otp-input" separator="-" :inputs="settings[i - 1].numbers"
                            :num-inputs="4" :should-auto-focus="true" lang="num"
                            @inputsChanged="inputsChanged($event, i - 1)" />
                    </div>

                </div>


            </b-card>
        </div>
        <b-card class="mb-3" v-if="step == 3">
            <h4>مكان وقوع الحادث</h4>
            <b-form-input v-model="form.location" :maxlength="100"
                placeholder="مثال: الرياض، حي المرسلات طريق ... بجانب مدارس العليا الأهلية" />
            <small class="text-secondary">يفترض أن يكون هنا خرائط قوقل وتحديد الموقع الفعلي ولكن ذلك يتطلب إشتراك في الـ API
                والغرض من هذا الموقع تعليمي لذلك تم الإستغناء عنه</small>


            <h4 class="mt-2">كيف صار الحادث؟</h4>
            <b-form-textarea v-model="form.notes" placeholder="يرجى شرح كيف تم الحادث بشكل كامل" rows="3" max-rows="5" />


            <h4 class="mt-2">رقم جوال للتواصل</h4>
            <b-form-input v-model="form.contact" :maxlength="100" maxlength="10"
                :placeholder="$store.state.userData.phone" />
            <small>يجب ان يبدأ بـ 05</small>

        </b-card>

        <b-card title="صور ومقاطع للحادث" class="mb-1" v-if="step == 4">
            <b-alert class="mb-2" show variant="warning">
                <div class="alert-body d-flex align-items-start">
                    <feather-icon class="mr-1" size="50" icon="AlertCircleIcon" />
                    <span class="text-warning mb-0">يجب تقديم لا يقل عن 5 صور للحادث تبين الحادث بشكل واضح وتوضح فيها لوحات
                        السيارات المتضررة أو رقم الهيكل في حال تضرر اللوحات - يسمح بمشاركة مقاطع الفيديو
                    </span>

                </div>
            </b-alert>
            <b-form-file accept=".png, .jpg, .jpeg, video/mp4,video/x-m4v,video/*" multiple browse-text="تصفح"
                placeholder="حدد الملفات للحادث" :file-name-formatter="fileFormatter" ref="files" @change="onFileChange" />
            <div class="mt-2">
                <h4>معاينة الملفات ( {{ files.length }} ):</h4>
                <b-row>
                    <b-col class="my-1" md="4" v-for="(file) in files" :key="file.id">
                        <div class="hover-remove" v-if="files.map(f => f.id).includes(file.id)">
                            <b-button class="delete-button" @click="removeFile(file.id)">
                                <feather-icon icon="TrashIcon" class="mr-25" />
                                ازالة
                            </b-button>
                            <img class="preview" v-if="file.type.split('/')[0] == 'image'" :src="file.data" />
                            <video class="preview" v-else :src="file.data" controls></video>
                        </div>
                    </b-col>
                </b-row>

            </div>
        </b-card>
        <p v-for="error in errors" class="text-danger mb-25">- {{ error }}</p>
        <controllers />
        <component name="controllers">
            <b-row>
                <b-col>
                    <b-button block variant="outline-secondary" class="mt-2 mb-4"
                        @click="step > 0 ? step-- : $router.push({ name: 'home' })"><feather-icon
                            :icon="step > 0 ? 'ChevronRightIcon' : ''" class="mr-25" /> {{ step > 0 ? 'السابق' : 'الغاء'
                            }}</b-button>

                </b-col>
                <b-col>

                    <vs-tooltip :active="btnDisabled"
                        :text="btnDisabled ? 'لا يمكن إكمال البلاغ، يجب التواصل مع المرور' : ''" position="top">

                        <b-button :disabled="btnDisabled || Invalid[step].some(i => i == true)" block variant="primary"
                            class="mt-2 mb-4" :dir="step !== 4 ? 'ltr' : ''"
                            @click="step == 4 ? submit() : step++"><feather-icon
                                :icon="step !== 4 ? 'ChevronLeftIcon' : 'SendIcon'" class="mr-25" /> {{ step !== 4 ?
                                    'التالي' : 'إرسال' }}</b-button>
                    </vs-tooltip>
                </b-col>
            </b-row>
        </component>

    </div>
</template>

<script>
import { BCard, BRow, BCol, BProgress, BFormInput, BInputGroup, BFormFile, BImg, BFormTextarea, BButton, BAlert } from 'bootstrap-vue'
import { getAllLetters, getAllNumbers } from '@core/utils/translator.js'

import Cleave from 'vue-cleave-component'
import OtpInput from "@core/components/vue-otp-input";
import store from '@/store';
export default {
    components: {
        BRow,
        BFormTextarea,
        BCol,
        BAlert,
        BImg,
        BProgress,
        BFormFile,
        BInputGroup,
        BCard,
        BFormInput,
        Cleave,
        BButton,
        OtpInput
    },

    computed: {
        btnDisabled() {
            return this.form.with_trafic_violation == true || this.form.parties_count > this.maxPartiesForReport;
        },
        allLetters() {
            const allLetters = getAllLetters();
            let letters = [];
            Object.entries(allLetters).forEach(letter => {
                letters.push({ ar: letter[1], en: letter[0] })
            })
            return letters;
        },
        allNumbers() {
            return Object.keys(getAllNumbers());
        },
        Invalid() {
            return {
                0: [this.form.with_trafic_violation == null, this.form.injuries == null, this.form.all_here == null, this.form.my_report == null],
                1: [this.form.parties_count == 0],
                2: [this.settings.every(c => c.numbers.length == 0 || c.englishInputs.length == 0)],
                3: [this.form.location.length < 5 || this.form.notes.length < 5 || this.form.notes.length > 300 || !this.form.contact.startsWith('05') || this.form.contact.length !== 10],
                4: [this.files.length < 5 || this.files.length > 20]
            }
        }
    },
    data() {
        return {
            files: [],
            settings: [{
                arabicInputs: [],
                englishInputs: [],
                numbers: [],
                type: 0
            },
            {
                arabicInputs: [],
                englishInputs: [],
                numbers: [],
                type: 0
            },
            {
                arabicInputs: [],
                englishInputs: [],
                numbers: [],
                type: 0
            },
            {
                arabicInputs: [],
                numbers: [],
                englishInputs: [],
                type: 0
            },
            {
                arabicInputs: [],
                englishInputs: [],
                numbers: [],
                type: 0
            },
            ],
            step: 0,
            arabicInputs: [],
            englishInputs: [],
            maxPartiesForReport: 5, // قابل للتعديل، ( الحد الأقصى من الأطراف لإستقبالة، أكثر من كذا يجب الإتصال على المرور )
            questions: [{ key: 'injuries', text: 'هل نتج اي إصابات من الحادث؟' }, { key: 'all_here', text: 'هل جميع الأطراف متواجدين في موقع الحادث؟' }, { key: 'my_report', text: 'هل أنت أحد الأطراف المتضرره؟' }, { key: 'with_trafic_violation', text: 'هل تم الحادث بسبب إرتكاب مخالفة مرورية؟' }],
            errors: [],
            plateTypes: [
                { name: 'خصوصي', id: 0 },
                { name: 'نقل', id: 1 }
            ],
            form: {
                with_trafic_violation: null,
                injuries: null,
                all_here: null,
                my_report: null,
                parties_count: 1,
                contact: store.state.userData.phone,
                files: [],
                notes: '',
                location: ''
            },

        }
    },
    methods: {
        inputsChanged(newData, num) {
            if (newData.lang == 'num') {
                this.settings[num].numbers = newData.values;
            }

            const test = JSON.parse(JSON.stringify(this.allLetters));
            if (newData.lang == 'en') {

                this.settings[num].englishInputs = newData.values;
                this.settings[num].arabicInputs = newData.values.map(v => {
                    const letter = test.filter(c => c.en == v.toUpperCase())[0]

                    return letter ? letter.ar : ''
                }).reverse()
            }
            if (newData.lang == 'ar') {
                this.settings[num].arabicInputs = newData.values;
                this.settings[num].englishInputs = newData.values.map(v => {
                    const letter = test.filter(c => c.ar == v)[0];
                    return letter ? letter.en : ''
                }).reverse()
            }

        },
        lettersInputKeydown(event, lang) {
            const char = event.key.toUpperCase();
            if (this.allLetters.map(c => c[lang]).includes(char)) {
                return true;
            } else event.preventDefault();
        },
        fileFormatter(files) {
            return files.length + ' ملفات محددة'
        },
        removeFile(id) {
            this.files = this.files.filter(f => f.id !== id)
        },
        submit() {
            let errs = [];
            this.errors = [];
            // this.form.cars = '';
            this.form.cars = this.settings
            const cc = this.settings.filter(s => s.englishInputs.length > 0 && s.numbers.length > 0).map(s => ({ letters: s.englishInputs.join(""), numbers: s.numbers.join(""), type: s.type }));
            // cc.forEach(s => {
            //     this.form.cars += JSON.stringify(s) + 'AND';
            // })
            this.form.cars = JSON.stringify(cc)

            let form = JSON.parse(JSON.stringify(this.form));

            if (form.notes.length > 300) errs.push('يجب الا تزيد الملاحظات عن 300 حرف');
            if (form.location.length < 5) errs.push('يرجى كتابة تفاصيل عن موقع الحادث');
            if (cc.some(c => c.letters.length < 3 && c.type == 1)) errs.push("لا يمكن للوحات النقل ان تكون اقل من 3 احرف")
            if (this.files.length < 5) errs.push('يجب عليك إرفاق 5 صور/مقاطع للحادث على الأقل')
            if (errs.length > 0) {
                this.errors = errs;
                return;
            }

            form.cars = JSON.stringify(form.cars);

            const formData = new FormData();
            Object.entries(form).forEach(e => {
                formData.append(e[0], e[1])
            })
            this.$refs.files.files.forEach(file => {
                formData.append('files', file)
            })

            fetch('https://api.njm.solutions/report/new', {
                credentials: 'include',
                method: 'POST',
                body: formData,
            })
                .then(res => res.json()).then(res => {
                    if (res.status === 401) {
                        this.$cookies.remove('v');
                        store.state.userData = undefined;
                        this.$router.push({ name: 'auth-login' });
                        return;
                    }
                    if (res.status == 200) {
                        if (res.id) {
                            this.$router.push({ name: 'report', params: { id: res.id } })
                        } else this.$router.push({ name: 'home' });
                    } else {
                        this.$swal({
                            title: 'حدث خطأ',
                            text: res.message || '',
                            icon: 'error',
                            customClass: {
                                confirmButton: 'btn btn-primary',
                            },
                            confirmButtonText: 'حسنًا',
                            buttonsStyling: false,
                        })
                    }
                })
        },
        onFileChange(event) {
            this.files = [];
            let files = event.target.files || event.dataTransfer.files;
            if (!files.length) {
                return;
            }
            let i = 0;
            for (const file of event.target.files) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    file.src = e.target.result;
                    this.files.push({
                        id: i,
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        data: e.target.result,
                    });
                    i++;
                };
                reader.readAsDataURL(file);

            }
        },
    }
}
</script>

<style lang="scss" scoped>
.preview {
    max-width: 500px;
    width: 100%;
}

.hover-remove {
    $icon-size: 30px;

    img {
        border-radius: 10px;
    }

    &:hover .delete-button {
        opacity: 1;
    }

    .delete-button {
        z-index: 99;
        opacity: 0;
        position: absolute;
        bottom: 10px;
        height: 35px;
        width: 170px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        padding: 3px;
        color: white;
        background-color: rgba(0, 0, 0, 0.3) !important;
        border-radius: 0.5rem;
        transition: border-radius 0.5s ease, background-color 0.2s ease, opacity 0.3s ease;

        &:hover {
            background-color: var(--danger) !important;
            border-radius: 5rem;
        }
    }


}

::v-deep .vs-input-number {
    box-shadow: 2px 2px;
    max-width: 200px;

    .vs-input-number--input {
        font-size: 1.4rem;
        padding-right: 0;
        padding-left: 0;
        width: 100% !important;
    }


}

.count-input {
    ::v-deep button {
        border-radius: 5px !important;
        min-width: 30px;
        min-height: 30px;

        i {
            font-size: 1rem;
        }
    }
}
</style>